import React from 'react'
import Layout from '../../components/Layout'
import ReceiptForm from '../../components/RentReceipt/ReceiptForm'
import { Seo } from '../../components/seo'


export default function RentReceiptGenerator() {
    return (
      <>
        <Seo
          title='Rent Receipt Generator'
          keywords='Keywords'
          description='Description'
          name='TODAYFILINGS'
          type='Website'
          author='TODAYFILINGS'
        />
        <Layout>
          <ReceiptForm />
        </Layout>
      </>
    );
}
