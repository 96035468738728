import React, { useRef, useState, useEffect } from 'react';
import FAQAccord from '../GFlush';
import ContentImg from '../GSTTemp/ContentImg';
import ImgContent from '../GSTTemp/ImgContent';
import GenerateReceipt from './GenerateReceipt';
import Receipts from './Receipts';
import RentDetails from './RentDetails';
import RentInformation from './RentInformation';
import { Link } from 'gatsby';

const ReceiptForm = () => {
  /* Img Content Component Data */
  const rentIcData = {
    id: 'rent-generator',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Generate free rent receipts in 4 steps',
    image: '/imgs/registration/ipr/td7.png',
    alt_tag: 'Online Rent Receipt Generator',
    points: [
      {
        head: '',
        content: `It is necessary to provide the employer with rent receipts in order
        to claim HRA exemptions.`,
        icon: true,
      },
      {
        head: '',
        content: `Fill in the necessary information, such as the
        monthly rent, the address of the rental home, the email address,
        the PAN number, etc.`,
        icon: true,
      },
      {
        head: '',
        content: `The rent receipt will be sent to your email address
        after you choose whether to generate it monthly, quarterly, half-yearly,
        or annually..`,
        icon: true,
      },
      {
        head: '',
        content: `Get the created receipt stamped and signed by
        the landlord, then download it as a pdf.`,
        icon: true,
      },
      {
        head: '',
        content: `Gain HRA exemption by giving your employer your rent receipts.`,
        icon: true,
      },
    ],
  };
  /*  Content Img Component Data */
  const rentCiData = {
    id: 'generate',
    background: 'main-content',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'How to generate rent receipts online?',
    paragraph: ``,
    points: [
      {
        head: `Why do we require rent receipts and what do they entail?`,
        content: `Rent receipt is a crucial document that serves as evidence of the rent that has
         been paid to the landlord. It should be gathered and stored safely because it is a crucial tool
          for tax savings. To claim their House Rent Allowance, salaried employees must present rent 
          receipts (HRA).`,
        icon: true,
      },
      {
        head: `How can I create online rent receipts?`,
        content: `It is necessary to provide the employer with rent receipts in order to claim HRA exemptions.`,
        icon: true,
      },
      {
        content: `This only requires three easy actions to complete.`,
        icon: true,
      },
      {
        content: `Fill in the rent paid and your address.`,
        icon: true,
      },
      {
        content: `Give your landlord and personal information.`,
        icon: true,
      },
      {
        content: `Choose the time frame for which you'd like to create a rent receipt (Monthly, Quarterly, Annually)`,
        icon: true,
      },
    ],
    image: '/imgs/business/form-filing.png',
    alt_tag: 'Online Rent Receipt Generator',
  };
  /* FaQ data */
  var RentGenFAQ = [
    {
      header: 'What information must be included on rent receipts?',
      body: [
        {
          content: `Renter's Name (If you are the tenant, fill in your name)`,
          icon: true,
        },
        {
          content: `Landlord's name`,
          icon: true,
        },
        {
          content: `Payment Amount.`,
          icon: true,
        },
        {
          content: `Payment Date.`,
          icon: true,
        },
        {
          content: `Rental Interval.`,
          icon: true,
        },
        {
          content: `House Number (Rented Property).`,
          icon: true,
        },
        {
          content: `Signature of the manager or landlord.`,
          icon: true,
        },
      ],
    },
    // 2
    {
      header:
        'How much tax benefit can be obtained by giving employers your rent receipts?',
      body: [
        {
          content: `The amount of HRA (House Rent Allowance) exemption you are 
                    qualified to claim is equal to the amount of tax savings on rent.`,
          icon: false,
        },
      ],
    },
    {
      header: `Do I need a rent receipt to submit an HRA claim?`,
      body: [
        {
          content: `An employee must give the employer a rent receipt as
                     proof of their right to HRA if they get more than Rs. 3000 in HRA each month.`,
          icon: true,
        },
        {
          content: `Try to retain your rent receipts on hand, even if your HRA is 
                    less than Rs. 3000, in case the income tax officer needs them later.`,
          icon: true,
        },
      ],
    },
    // 3
    {
      header: `Will e-receipt production include a revenue stamp?`,
      body: [
        {
          content: `No, you won't receive rent receipts with revenue stamps in 
                    printable form. If necessary, you can always purchase revenue stamps 
                    and apply them.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `Do I have to provide monthly rent receipts?`,
      body: [
        {
          content: `Employers frequently want rent receipts going back three to four months.`,
          icon: false,
        },
      ],
    },
  ];
  const [currentStep, setCurrentStep] = useState(1);
  const [typeMonths, setTypeMonths] = useState([]);
  const [validation, setValidation] = useState({
    start_date: '',
    end_date: '',
    rent_amount: '',
    rent_address: '',
    phone: '',
    email: '',
    name: '',
    landlord_name: '',
  });
  //state for form data
  const [formData, setFormData] = useState({
    rent_amount: '',
    rent_address: '',
    name: '',
    landlord_name: '',
    landlord_pan: '',
    email: '',
    phone: '',
    start_date: '',
    end_date: '',
    receipt_type: '',
    months: [],
    mon_range: '',
    // qmonth: [],
  });
  const [receiptStatus, setReceiptStatus] = useState(false);

  const sectionRef = useRef(null);

  const handlePrintClick = () => {
    window.print();
  };

  useEffect(() => {
    receipt_type_fun();
    // console.log(typeMonths);
  }, [formData.receipt_type]);

  const receipt_type_fun = () => {
    let startDate = new Date(formData.start_date);
    let endDate = new Date(formData.end_date);

    /* quaters and date  */
    const getQuarters = (startDate, endDate) => {
      const quarters = [];

      let currDate = new Date(startDate);
      let quarterStartMonth, quarterEndMonth, quarterEnd;

      while (currDate < endDate) {
        quarterStartMonth = currDate.toLocaleString('default', {
          month: 'short',
        });
        currDate.setMonth(currDate.getMonth() + 2);
        quarterEndMonth = currDate.toLocaleString('default', {
          month: 'short',
        });

        let quarterStart = new Date(
          currDate.getFullYear(),
          currDate.getMonth() - 1,
          -29
        );

        quarterEnd = new Date(
          currDate.getFullYear(),
          currDate.getMonth() - 0,
          32
        );
        quarters.push({
          month_text: `${quarterStartMonth} ${quarterStart.getFullYear()} - ${quarterEndMonth} ${quarterEnd.getFullYear()}`,
          each_start_date: quarterStart.toISOString().slice(0, 10),
          each_end_date: quarterEnd.toISOString().slice(0, 10),
        });
        currDate.setMonth(currDate.getMonth() + 1);
      }

      return quarters;
    };
    /* HalfYears and Dates */
    const getHalfs = (startDate, endDate) => {
      const result = [];

      let currDate = new Date(startDate);
      let halfYearStartMonth, halfYearEndMonth, halfYearEnd;

      while (currDate < endDate) {
        halfYearStartMonth = currDate.toLocaleString('default', {
          month: 'short',
        });
        currDate.setMonth(currDate.getMonth() + 5);
        halfYearEndMonth = currDate.toLocaleString('default', {
          month: 'short',
        });

        let halfYearStart = new Date(
          currDate.getFullYear(),
          currDate.getMonth() - 5,
          2
        );

        halfYearEnd = new Date(
          currDate.getFullYear(),
          currDate.getMonth() + 1,
          1
        );
        result.push({
          month_text: `${halfYearStartMonth} ${halfYearStart.getFullYear()} - ${halfYearEndMonth} ${halfYearEnd.getFullYear()}`,
          each_start_date: halfYearStart.toISOString().slice(0, 10),
          each_end_date: halfYearEnd.toISOString().slice(0, 10),
        });
        currDate.setMonth(currDate.getMonth() + 1);
      }

      return result;
    };

    if (formData.receipt_type === '1') {
      let months_arr = typeMonths;
      for (let d = startDate; d <= endDate; d.setMonth(d.getMonth() + 1)) {
        // Get the last day of the current month
        let lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 1);

        // Set the starting date to the first day of the current month
        // let each_start_date = new Date(d.getFullYear(), d.getMonth(), 1);
        let each_mon_start = d;

        // Set the ending date to the last day of the current month
        let each_mon_end =
          lastDayOfMonth.getDate() === 28 && lastDayOfMonth.getMonth() === 1
            ? new Date(d.getFullYear(), d.getMonth(), 28)
            : lastDayOfMonth;

        // Create an object with the month and year as text, as well as the starting and ending dates
        let each_obj = {
          month_text: d.toLocaleString('default', {
            month: 'short',
            year: 'numeric',
          }),
          each_start_date: each_mon_start.toISOString().slice(0, 10),
          each_end_date: each_mon_end.toISOString().slice(0, 10),
        };

        // Add the object to the array
        months_arr.push(each_obj);
      }

      setTypeMonths(months_arr);
    } else if (formData.receipt_type === '3') {
      const quarters = getQuarters(startDate, endDate);

      const QyRent = formData.rent_amount * 3;

      setTypeMonths(quarters);
      setFormData({
        ...formData,
        rent_amount: QyRent.toString().replace(
          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
          ','
        ),
      });
    } else if (formData.receipt_type === '12') {
      // get the year and month from the start date
      const startYear = startDate.getFullYear();
      const startMonth = startDate.getMonth();
      const endYear = endDate.getFullYear();
      const endMonth = endDate.getMonth();
      const options = { year: 'numeric', month: 'short' };

      // construct a new date representing the start of the period
      const startOfPeriod = new Date(
        startYear,
        startMonth,
        1
      ).toLocaleDateString('en-US', options);
      const endOfPeriod = new Date(endYear, endMonth, 1).toLocaleDateString(
        'en-US',
        options
      );
      const yearlyReceipt = [`${startOfPeriod} - ${endOfPeriod}`];
      const yRent = formData.rent_amount * 12;
      setFormData({
        ...formData,
        rent_amount: yRent
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
        mon_range: yearlyReceipt,
      });
    } else if (formData.receipt_type === '6') {
      const result = getHalfs(startDate, endDate);

      setTypeMonths(result);
      const hy_rent = formData.rent_amount * 6;
      setFormData({
        ...formData,
        rent_amount: hy_rent
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
      });
    }
  };

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (e) => {
    // input value from the form
    const { name, value } = e.target;
    //updating for data state taking previous state and then adding new value to create new object
    setFormData({ ...formData, [name]: value });
  };

  const scollToRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if required fields are filled out
    let isValid = true;
    const validationErrors = {};

    // Only validate fields from previous steps if they have been visited
    // if (currentStep >= 2) {
    if (!formData.rent_amount) {
      validationErrors.rent_amount = 'Enter Rent Amount';
      isValid = false;
    }
    if (!formData.rent_address) {
      validationErrors.rent_address = 'Enter Rent Address';
      isValid = false;
    }
    // }
    // if (currentStep >= 3) {
    if (!formData.name) {
      validationErrors.name = 'Enter Your Name';
      isValid = false;
    }
    if (!formData.landlord_name) {
      validationErrors.landlord_name = 'Enter Landlord Name';
      isValid = false;
    }
    // }
    // if (currentStep >= 4) {
    if (!formData.email) {
      validationErrors.email = 'Enter Your Email';
      isValid = false;
    }
    if (!formData.phone) {
      validationErrors.phone = 'Enter Phone Number';
      isValid = false;
    }
    if (!formData.start_date) {
      validationErrors.start_date = 'Select Start Date';
      isValid = false;
    }
    if (!formData.end_date) {
      validationErrors.end_date = 'Select End Date';
      isValid = false;
    }
    // }

    // Update validation state with error messages if fields are missing
    setValidation(validationErrors);

    // Submit form if all required fields are present
    if (isValid) {
      setReceiptStatus(true);
      scollToRef.current.scrollIntoView();
    } else {
      // check if rent_amount field has an error
      if (validationErrors.rent_amount) {
        // navigate back to the previous step
        setCurrentStep(currentStep - 2);
      }
      // check if rent_address field has an error
      if (validationErrors.rent_address) {
        // navigate back to the previous step
        setCurrentStep(currentStep - 2);
      }
      // check if name field has an error
      if (validationErrors.name) {
        // navigate back to the previous step
        setCurrentStep(currentStep - 1);
      }
      // check if landlord_name field has an error
      if (validationErrors.landlord_name) {
        // navigate back to the previous step
        setCurrentStep(currentStep - 1);
      }
    }
  };

  const renderSwitch = (param) => {
    switch (param) {
      // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 1:
        return (
          <RentDetails
            nextStep={nextStep}
            handleFormData={handleInputData}
            values={formData}
            validation={validation}
          />
        );
      // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 2:
        return (
          <RentInformation
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
            validation={validation}
          />
        );
      // Only formData is passed as prop to show the final value at form submit
      case 3:
        return (
          <GenerateReceipt
            prevStep={prevStep}
            handleFormData={handleInputData}
            handleSubmit={handleSubmit}
            values={formData}
            validation={validation}
          />
        );
      // default case to show nothing
      default:
        return <div></div>;
    }
  };
  return (
    <>
      <section className='house-rent'>
        <div className='container  mt-5'>
          <div className='row house-rent-receipt mt-5'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center p-0 mt-3 mb-2'>
              <div className=' px-0 pt-4 pb-0 mt-5 mb-3'>
                {/* <h2 id='heading' className='mt-5'>
                            Rent Receipt Generator
                        </h2>
                        <p>Fill all form field to go to next step</p> */}
                <form id='msform'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='rent-text'>
                        <h1 className='rent-head section-header'>
                          Prepare Online Rental Receipts for Free
                          <div className='bar'></div>
                        </h1>
                        <p className='rent-paragraph'>
                          Save yourself the trouble of organizing your rent
                          receipts at the last minute to claim an HRA exemption.
                          Save money on taxes by using our free rent receipt
                          maker. It is quick, practical, and simple.
                        </p>
                      </div>
                      <div className='rent-img'>
                        <img
                          src='/imgs/rent.png'
                          alt='Generate Free Online Rent Receipt'
                          class='rent_img img-fluid'
                        />
                      </div>
                    </div>
                    <div className='col-md-6 mt-5'>
                      <ul id='rent-progressbar'>
                        <li
                          className={
                            currentStep === 1
                              ? 'active'
                              : currentStep > 1
                              ? 'active'
                              : ''
                          }
                        >
                          <strong className='progress-text'>
                            Rent Details
                          </strong>
                        </li>
                        <li
                          className={
                            currentStep === 2
                              ? 'active'
                              : currentStep > 2
                              ? 'active'
                              : ''
                          }
                        >
                          <strong className='progress-text'>
                            Rent Information
                          </strong>
                        </li>
                        <li
                          className={
                            currentStep === 3
                              ? 'active'
                              : currentStep > 3
                              ? 'active'
                              : ''
                          }
                        >
                          <strong className='progress-text'>
                            Generate Receipt
                          </strong>
                        </li>
                      </ul>

                      {renderSwitch(currentStep)}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ImgContent item={rentIcData} />
      <section ref={scollToRef}></section>
      <div ref={sectionRef} className='print-receipts '>
        <div className='container d-flex align-items-center justify-content-end'>
          <button
            className='print-btn btn btn-primary'
            onClick={handlePrintClick}
          >
            Print
          </button>
        </div>
        <div className='receipts' id='receipts'>
          {receiptStatus
            ? formData.receipt_type === '1'
              ? typeMonths.map((month, index) => (
                  <Receipts
                    month={month}
                    index={index}
                    receiptStatus={receiptStatus}
                    formData={formData}
                  />
                ))
              : ''
            : ''}
          {receiptStatus
            ? formData.receipt_type === '3'
              ? typeMonths.map((quarters, index) => (
                  <Receipts
                    month={quarters}
                    index={index}
                    receiptStatus={receiptStatus}
                    formData={formData}
                  />
                ))
              : ''
            : ''}
          {receiptStatus
            ? formData.receipt_type === '6'
              ? typeMonths.map((result, index) => (
                  <Receipts
                    month={result}
                    index={index}
                    receiptStatus={receiptStatus}
                    formData={formData}
                  />
                ))
              : ''
            : ''}
          {receiptStatus ? (
            formData.receipt_type === '12' ? (
              <section id='rent-receipt' className='receipt'>
                <div className='container mt-5 receipt-container'>
                  <h1
                    className='section-header'
                    style={{ paddingBottom: '10px' }}
                  >
                    <span className='d-flex align-items-center month_text_span'>
                      Rent Receipt
                      <span className='month_text'>
                        ({receiptStatus ? formData.mon_range : ''})
                      </span>
                    </span>
                    <div className='bar'></div>
                  </h1>
                  <p className='receipt-content-top'>
                    Received a sum of
                    <strong className='rent-amt'>
                      ₹.{receiptStatus ? formData.rent_amount : '______'}/-
                    </strong>
                    from
                    <strong className='rent-payer'>
                      Mr/Mrs.{receiptStatus ? formData.name : '______'}
                    </strong>
                    towards the rent of property
                  </p>
                  <div className='row receipt-row'>
                    <div className='col-md-6'>
                      <p className='receipt-content'>
                        located at
                        <strong className='rent-address'>
                          {receiptStatus ? formData.rent_address : '______'}
                        </strong>
                        for the period
                      </p>
                      <p className='receipt-content'>
                        from
                        <strong className='rent-date'>
                          {receiptStatus ? formData.start_date : '______'}
                        </strong>
                        to
                        <strong className='rent-date'>
                          {receiptStatus ? formData.end_date : '______'}
                        </strong>
                      </p>
                    </div>
                    <div className='col-md-6 text-end mt-4'>
                      <p className='land-lord1'>
                        Name<span className='land-lord'>(Landlord)</span>
                        <strong className=''>
                          {receiptStatus ? formData.landlord_name : '______'}
                        </strong>
                      </p>
                      <p className='land-lord1'>
                        PAN<span className='land-lord'>(Landlord)</span>
                        <strong className=''>
                          {receiptStatus ? formData.landlord_pan : '______'}
                        </strong>
                      </p>

                      <p className='signature mt-5 text-end dmdnone'>
                        Signature<span className='land-lord'>(Landlord)</span>
                      </p>
                    </div>
                  </div>

                  <div className='row receipt-row'>
                    <h6 className=' mb-0'>
                      <span>Date: </span>
                      <strong>
                        {receiptStatus ? formData.end_date : '______'}
                      </strong>
                    </h6>
                    <div className='col-md-6'>
                      <p className='generated-by mt-2'>
                        Receipt generated by
                        <Link href='https://todayfilings.com/'>
                          todayfilings.com
                        </Link>
                      </p>
                    </div>
                    <div className='col-md-6 signature mt-2 text-end d-none d-md-block'>
                      <p className=''>
                        Signature<span className='land-lord'>(Landlord)</span>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              ''
            )
          ) : (
            <section id='rent-receipt' className='receipt'>
              <div className='container mt-5 receipt-container'>
                <h1
                  className='section-header'
                  style={{ paddingBottom: '10px' }}
                >
                  Rent Receipt
                  <div className='bar'></div>
                </h1>
                <p className='receipt-content-top'>
                  Received a sum of
                  <strong className='rent-amt'>
                    ₹.{receiptStatus ? formData.rent_amount : '______'}/-
                  </strong>
                  from
                  <strong className='rent-payer'>
                    Mr/Mrs.{receiptStatus ? formData.name : '______'}
                  </strong>
                  towards the rent of property
                </p>
                <div className='row receipt-row'>
                  <div className='col-md-6'>
                    <p className='receipt-content'>
                      located at
                      <strong className='rent-address'>
                        {receiptStatus ? formData.rent_address : '______'}
                      </strong>
                      for the period
                    </p>
                    <p className='receipt-content'>
                      from
                      <strong className='rent-date'>
                        {receiptStatus ? formData.start_date : '______'}
                      </strong>
                      to
                      <strong className='rent-date'>
                        {receiptStatus ? formData.end_date : '______'}
                      </strong>
                    </p>
                  </div>
                  <div className='col-md-6 text-end mt-4'>
                    <p className='land-lord1'>
                      Name<span className='land-lord'>(Landlord)</span>
                      <strong className=''>
                        {receiptStatus ? formData.landlord_name : '______'}
                      </strong>
                    </p>
                    <p className='land-lord1'>
                      PAN<span className='land-lord'>(Landlord)</span>
                      <strong className=''>
                        {receiptStatus ? formData.landlord_pan : '______'}
                      </strong>
                    </p>

                    <p className='signature mt-5 text-end dmdnone'>
                      Signature<span className='land-lord'>(Landlord)</span>
                    </p>
                  </div>
                </div>

                <div className='row receipt-row'>
                  <div className='col-md-6'>
                    <p className='generated-by mt-5'>
                      Receipt generated by
                      <Link href='https://todayfilings.com/'>
                        todayfilings.com
                      </Link>
                    </p>
                  </div>
                  <div className='col-md-6 signature mt-5 text-end d-none d-md-block'>
                    <p className=''>
                      Signature<span className='land-lord'>(Landlord)</span>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>

      <ContentImg CISection_data={rentCiData} />
      <section className='rent-faq'>
        <FAQAccord items={RentGenFAQ} />
      </section>
    </>
  );
};
export default ReceiptForm;
