import React from 'react';

const RentDetails = ({ nextStep, handleFormData, values, validation }) => {
  return (
    <div className='row rent'>
      <div className='col-md-2'></div>
      <div className='col-md-8 card'>
        <div className='tax-input-block-border'>
          <div className='mb-3'>
            <label htmlFor='Rent Amount' className='form-label'>
              Rent Amount<span className='text-danger required'>*</span>
            </label>
            <input
              type='text'
              className='rent-input form-control'
              id='rent_amount'
              name='rent_amount'
              required
              autoComplete='off'
              value={values.rent_amount}
              onChange={handleFormData}
            />
            {validation.rent_amount ? (
              <p className='valid_error text-left text-danger'>
                {validation.rent_amount}
              </p>
            ) : (
              ''
            )}
          </div>
          <div className='mb-3'>
            <label htmlFor='Rented House Address' className='form-label'>
              Rented House Address
              <span className='text-danger required'>*</span>
            </label>
            <input
              type='text'
              className='rent-input form-control'
              id='rent_address'
              name='rent_address'
              required
              autoComplete='off'
              value={values.rent_address}
              onChange={handleFormData}
            />
            {validation.rent_address ? (
              <p className='valid_error text-left text-danger'>
                {validation.rent_address}
              </p>
            ) : (
              ''
            )}
          </div>

          <div className='col-md-12 '>
            <button
              type='submit'
              className='btn btn-md form-next-btn btn-success m-2'
              onClick={nextStep}
            >
              Next <i className='bi bi-arrow-right'></i>
            </button>
          </div>
        </div>
      </div>
      <div className='col-md-2'></div>
    </div>
  );
};

export default RentDetails;
