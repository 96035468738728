import React from 'react'

const GenerateReceipt = ({
  handleFormData,
  values,
  prevStep,
  handleSubmit,
  validation,
}) => {
  return (
    <div className='row rent'>
      <div className='col-md-2'></div>
      <div className='col-md-8 card'>
        <div className='tax-input-block-border'>
          <div className='mb-3'>
            <label htmlFor='Your Email' className='form-label'>
              Email<span className='text-danger required'>*</span>
            </label>
            <input
              type='email'
              className='rent-input form-control'
              id='email'
              name='email'
              autoComplete='off'
              required
              value={values.email}
              onChange={handleFormData}
            />
            {validation.email ? (
              <p className='valid_error text-left text-danger'>
                {validation.email}
              </p>
            ) : (
              ''
            )}
          </div>

          <div className='mb-3'>
            <label htmlFor='Phone' className='form-label'>
              Phone<span className='text-danger required'>*</span>
            </label>
            <input
              type='phone'
              className='rent-input form-control'
              id='phone'
              name='phone'
              autoComplete='off'
              value={values.phone}
              onChange={handleFormData}
              required
            />
            {validation.phone ? (
              <p className='valid_error text-left text-danger'>
                {validation.phone}
              </p>
            ) : (
              ''
            )}
          </div>

          <div className='mb-3'>
            <label htmlFor='Time Period' className='form-label'>
              Time Period for generate the receipt
            </label>
            <div className='row'>
              <div className='col-md-6'>
                <label htmlFor='Start Date' className='form-label'>
                  Start Date<span className='text-danger required'>*</span>
                </label>
                <input
                  type='date'
                  className='rent-input form-control'
                  id='start_date'
                  name='start_date'
                  autoComplete='off'
                  value={values.start_date}
                  onChange={handleFormData}
                  required
                />
                {validation.start_date ? (
                  <p className='valid_error text-left text-danger'>
                    {validation.start_date}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className='col-md-6'>
                <label htmlFor='End Date' className='form-label'>
                  End Date<span className='text-danger required'>*</span>
                </label>
                <input
                  type='date'
                  className='rent-input form-control'
                  id='end_date'
                  name='end_date'
                  autoComplete='off'
                  value={values.end_date}
                  onChange={handleFormData}
                  required
                />
                {validation.end_date ? (
                  <p className='valid_error text-left text-danger'>
                    {validation.end_date}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <label htmlFor='Time Period' className='form-label'>
              How would you like your receipts to be?
            </label>
            <select
              name='receipt_type'
              value={values.receipt_type}
              onChange={handleFormData}
              className='form-select'
              aria-label='Default select example'
              defaultValue='0'
            >
              <option value='0'>Select Period</option>
              <option value='1'>Monthly</option>
              <option value='3'>Quarterly</option>
              <option value='6'>Half yearly</option>
              <option value='12'>Yearly</option>
            </select>
          </div>
          <div className='col-md-12 '>
            <button
              className='btn btn-md  form-prev-btn btn-primary'
              onClick={prevStep}
            >
              <i className='bi bi-arrow-left'></i>Back
            </button>
            <button
              type='submit'
              className='btn btn-md form-next-btn btn-success m-2'
              onClick={handleSubmit}
            >
              Generate <i className='bi bi-arrow-right'></i>
            </button>
          </div>
        </div>
      </div>
      <div className='col-md-2'></div>
    </div>
  );
};

export default GenerateReceipt