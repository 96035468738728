import React from 'react';

const RentInformation = ({
  nextStep,
  handleFormData,
  values,
  prevStep,
  validation,
}) => {
  return (
    <div className='row rent'>
      <div className='col-md-2'></div>
      <div className='col-md-8 card'>
        <div className='tax-input-block-border'>
          <div className='mb-3'>
            <label htmlFor='Your Name' className='form-label'>
              Your Name<span className='text-danger required'>*</span>
            </label>
            <input
              type='text'
              className='rent-input form-control'
              id='name'
              name='name'
              required
              autoComplete='off'
              value={values.name}
              onChange={handleFormData}
            />
            {validation.name ? (
              <p className='valid_error text-left text-danger'>
                {validation.name}
              </p>
            ) : (
              ''
            )}
          </div>
          <div className='mb-3'>
            <label htmlFor='Landlord Name' className='form-label'>
              Landlord Name<span className='text-danger required'>*</span>
            </label>
            <input
              type='text'
              className='rent-input form-control'
              id='landlord_name'
              name='landlord_name'
              autoComplete='off'
              required
              value={values.landlord_name}
              onChange={handleFormData}
            />
            {validation.landlord_name ? (
              <p className='valid_error text-left text-danger'>
                {validation.landlord_name}
              </p>
            ) : (
              ''
            )}
          </div>
          <div className='mb-3'>
            <label htmlFor='Landlord PAN' className='form-label'>
              Landlord PAN(Optional)
            </label>
            <input
              type='text'
              className='rent-input form-control'
              id='landlord_pan'
              name='landlord_pan'
              autoComplete='off'
              value={values.landlord_pan}
              onChange={handleFormData}
            />
          </div>
          <div className='col-md-12 '>
            <button
              className='btn btn-md  form-prev-btn btn-primary'
              onClick={prevStep}
            >
              <i className='bi bi-arrow-left'></i>Back
            </button>
            <button
              className='btn btn-md form-next-btn btn-success m-2'
              onClick={nextStep}
            >
              Next <i className='bi bi-arrow-right'></i>
            </button>
          </div>
        </div>
      </div>
      <div className='col-md-2'></div>
    </div>
  );
};

export default RentInformation;
