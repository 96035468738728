import { Link } from 'gatsby';
import React from 'react';

const Receipts = ({ receiptStatus, formData, month, index }) => {
  // console.log(month);
  return (
    <section
      id='rent-receipt'
      className='receipt'
      key={index}
      style={{ marginTop: '100px' }}
    >
      <div className='container mt-5 receipt-container'>
        <h1 className='section-header ' style={{ paddingBottom: '10px' }}>
          <span className='d-flex align-items-center month_text_span'>
            Rent Receipt
            <span className='month_text'> ({month.month_text})</span>
          </span>
          <div className='bar'></div>
        </h1>
        <p className='receipt-content-top'>
          Received a sum of
          <strong className='rent-amt'>
            ₹.{receiptStatus ? formData.rent_amount : '______'}/-
          </strong>
          from
          <strong className='rent-payer'>
            Mr/Mrs.{receiptStatus ? formData.name : '______'}
          </strong>
          towards the rent of property
        </p>
        <div className='row receipt-row'>
          <div className='col-md-6'>
            <p className='receipt-content'>
              located at
              <strong className='rent-address'>
                {receiptStatus ? formData.rent_address : '______'}
              </strong>
              for the period
            </p>
            <p className='receipt-content'>
              from
              <strong className='rent-date'>
                {receiptStatus ? month.each_start_date : '______'}
              </strong>
              to
              <strong className='rent-date'>
                {receiptStatus ? month.each_end_date : '______'}
              </strong>
            </p>
          </div>
          <div className='col-md-6 text-end mt-4'>
            <p className='land-lord1'>
              Name<span className='land-lord'>(Landlord)</span>
              <strong className=''>
                {receiptStatus ? formData.landlord_name : '______'}
              </strong>
            </p>
            <p className='land-lord1'>
              PAN<span className='land-lord'>(Landlord)</span>
              <strong className=''>
                {receiptStatus ? formData.landlord_pan : '______'}
              </strong>
            </p>

            <p className='signature mt-5 text-end dmdnone'>
              Signature<span className='land-lord'>(Landlord)</span>
            </p>
          </div>
        </div>

        <div className='row receipt-row'>
          <h6 className=' mb-0'>
            <span>Date: </span>
            <strong>{receiptStatus ? formData.end_date : '______'}</strong>
          </h6>
          <div className='col-md-6'>
            <p className='generated-by mt-2'>
              Receipt generated by 
              <Link href='https://todayfilings.com/'>todayfilings.com</Link>
            </p>
          </div>
          <div className='col-md-6 signature mt-2 text-end d-none d-md-block'>
            <p className=''>
              Signature<span className='land-lord'>(Landlord)</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Receipts;
